import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Login.css';

const Login = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Check if the user is already logged in
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      navigate('/main', { state: { mobileNumber: loggedInUser } });
    }
  }, [navigate]);

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/login', {
        mobileNumber,
        password,
      });
      const { message , status } = response.data;
      console.log(response.data);
      if (status === 1) {
      toast.success('Login successful', { position: 'top-center' });
      localStorage.setItem('user', mobileNumber);
      navigate('/main', { state: { mobileNumber } });
      } else {
        toast.warning(message, { position: 'top-center' });
      }
      
    } catch (error) {
      toast.warning('Login failed:' + (error.response?.data?.message || error.message), { position: 'top-center' });
      // setError('Login failed: ' + (error.response?.data?.message || error.message));
    }
  };

  return (
    <div className="auth-container">
      <header className="header">
        <img src="../assets/images/logo.png" alt="Logo" className="logo2" />
        <button className="auth-button" onClick={handleSignUpClick}>Register</button>
      </header>
      <div className="auth-form">
        <h2 className="auth-header">Sign in</h2>
        <div className="input-container">
          <span>+91</span>
          <input
            type="text"
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            placeholder="Mobile number"
          />
        </div>
        <div className="input-container">
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
          />
        </div>
        <button className="otp-button" onClick={handleLogin}>Login</button>
        <ToastContainer />
        <p className="footer-text">
          By continuing I agree that MB SMART WEB PRIVATE LIMITED may store and process my data in accordance with the 
           About Contact Privacy Policy
          and that I am 18 years or older. I am not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
        </p>
      </div>
    </div>
  );
};

export default Login;
