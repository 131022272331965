// src/components/Banner.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Banner = () => {
  return (
    <div>
    <Box p={2} textAlign="center" style={{ backgroundColor: 'transparent', color: '#FFF' }}>
      <Typography variant="h6">केवल 5% कमीशन शुल्क, रैफर पर 3% बोनस</Typography>
      <Typography variant="subtitle1">Fast withdrawal, Live Chat Support</Typography>
    </Box>
    </div>
  );
};

export default Banner;
