import React, { useState, useEffect } from 'react';
import '../styles/TransactionHistory.css'; // Make sure to create this file for styling

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [mobile, setMobile] = useState("");
  const transactionsPerPage = 10; // Adjust the number of transactions per page as needed

  useEffect(() => {
    const userMobileNumber = localStorage.getItem("user");
    if (userMobileNumber) {
      setMobile(userMobileNumber);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!mobile) return; // Don't fetch if mobile number is not available

      try {
        const response = await fetch('https://api.successfamily.live/api/statement', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mobileNumber: mobile }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Assuming API response structure matches or needs slight adjustment
        const formattedData = data.map(transaction => ({
          type: transaction.Type,
          status: transaction.Mode,
          amount: transaction.amount,
          date: new Date(transaction.Time),
        }));

        setTransactions(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [mobile]); // Fetch data when `mobile` changes

  // Pagination logic
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Helper function to format date
  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC'
    });
  };

  return (
    <div className="transaction-history">
      <h2>History</h2>
      <p style={{ color: '#000' }}>You can see your Transaction, Gaming and Referral History here.</p>
      <div className="tabs">
        <button className="active">Coins</button>
        {/* <button>Earnings</button> */}
      </div>
      <div className="transactions">
        {currentTransactions.map((transaction, index) => (
          <div className="transaction" key={index}>
            <div className="transaction-details">
              <div className="transaction-date">
                <p>{formatDate(transaction.date)}</p>
              </div>
              <div className="transaction-info">
                <p className={transaction.type === "Credit" ? "Credit" : "Debit"} style={{ fontSize: '20px' }}>
                  {transaction.type}
                </p>
                <p>{transaction.status}</p>
              </div>
              <div className="transaction-amount">
                <p className={transaction.type === "Credit" ? "Credit" : "Debit"}>
                  {transaction.type === "Credit"
                    ? `+ ${transaction.amount}`
                    : `- ${transaction.amount}`}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        {[...Array(Math.ceil(transactions.length / transactionsPerPage)).keys()].map(number => (
          <button key={number + 1} onClick={() => paginate(number + 1)} className={currentPage === number + 1 ? 'active' : ''}>
            {number + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TransactionHistory;
