// src/components/BottomMenuBar.js
import React from 'react';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { Home, Support, Wallet, SupervisorAccount, AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import '../styles/BottomMenuBar.css';

const BottomMenuBar = () => {
  return (
    <AppBar position="fixed" style={{ top: 'auto', bottom: 0, backgroundColor: '#ff726f' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Link to="/home">
        <IconButton color="inherit">
          <Home />
        </IconButton>
        </Link>
        <Link to="/wallet">
        <IconButton color="inherit">
          <Wallet />
        </IconButton>
        </Link>
        <Link to="/refer">
        <IconButton color="inherit">
          <SupervisorAccount />
        </IconButton>
        </Link>
        <Link to="/support">
        <IconButton color="inherit">
          <Support />
        </IconButton>
        </Link>
        <Link to="/profile">
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default BottomMenuBar;
