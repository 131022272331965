import React, { useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AddCash.css';
import axios from 'axios';

const Withdrawal = () => {
    const [mobile, setMobile] = useState('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
        const userMobileNumber = localStorage.getItem('user');
        if (userMobileNumber) {
            setMobile(userMobileNumber);
        }
      }, []);

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };
    const handleSubmit = async () => {
      if (amount < 300) {
        // Show a warning toast if the amount is less than 300
        toast.warning('Withdrawal amount must be at least 300', { position: 'top-center' });
        return; // Exit the function early
      }
        try {
          const response = await axios.post('https://api.successfamily.live/api/withdrawalcash', {
            mobile,
            amount,
          });
          const { message , status } = response.data;
          console.log(response.data);
          if (status === 1) {
          toast.success('Withdrawal Submit Successfully', { position: 'top-center' });
          } else {
            toast.warning(message, { position: 'top-center' });
          }
          
        } catch (error) {
          toast.warning('Withdrawal failed:' + (error.response?.data?.message || error.message), { position: 'top-center' });
        }
      };

    return ( 
        <>
        <div className="Addcashheading">
        <h1>Withdrawal Money</h1>
        </div>
        <div className="add-cash-container">
            <h4>TDS (5%) Will Be Deducted After Annual Referral Earning Of 15000</h4>
            <div className="input-section">
                <label htmlFor="amount">Enter Amount (Minimum 300)</label>
                <div className="amount-input">
                    <span>💰</span>
                    <input 
                        type="number" 
                        id="amount" 
                        value={amount} 
                        onChange={handleAmountChange} 
                    />
                </div>
            </div>
            <button className="next-button" onClick={handleSubmit}>Withdrawal</button>
            <ToastContainer />
        </div>
        </>
    );
};

export default Withdrawal;
