import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { FaBars } from 'react-icons/fa';
import '../styles/Header.css';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [mainBalance, setMainBalance] = useState('0');
  const [upgradeBalance, setUpgradeBalance] = useState('0');
  const [mobileNumber, setMobileNumber] = useState('0');

  useEffect(() => {
    const userMobileNumber = localStorage.getItem('user');
    if (userMobileNumber) {
      fetchWalletBalance(userMobileNumber);
      setMobileNumber(userMobileNumber);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleAddCashClick = () => {
    navigate('/addcash');
  };
  const handleWithdrawalClick = () => {
    navigate('/withdrawal');
  };
  const handleWalletClick = () => {
    navigate('/Wallet');
  };

  const fetchWalletBalance = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/walletbalance', {
        mobileNumber
      });

      const { mainbal, upgradebal } = response.data;
      setMainBalance(mainbal);
      setUpgradeBalance(upgradebal);
    } catch (error) {
      console.error('Failed to fetch wallet balance:', error.message);
      // Handle error, set balances to default or show an error message
      setMainBalance('0');
      setUpgradeBalance('0');
    }
  };

  useEffect(() => {
    if (!mobileNumber) {
      return;
    }

    // Fetch balance immediately on component mount
    fetchWalletBalance(mobileNumber);

    // Set up interval to fetch balance every 7 seconds
    const interval = setInterval(() => {
      fetchWalletBalance(mobileNumber);
    }, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [mobileNumber]);

  return (
    <>
      <AppBar position="fixed" style={{backgroundColor: '#ff726f'}}>
        <div class="header_top_message "><span class="">COMMISSION: 5% : REFERRAL: 3% FOR ALL GAMES</span></div>
        <Toolbar>
          <div className="menu-icon" onClick={toggleSidebar}>
            <FaBars />
          </div>
          {isOpen && (
            <div className="overlay" onClick={toggleSidebar} />
          )}
          <Sidebar ref={sidebarRef} isOpen={isOpen} toggleSidebar={toggleSidebar} />
          <Typography variant="subtitle1" style={{ flexGrow: '1', lineHeight:0 }}>
            <img className='logo' src="../assets/images/favicon.png" alt="84LudoHub" style={{ height: '40',marginLeft: '10px' }} />
          </Typography>
          {/* <button onClick={handleAddCashClick} className='depositButton'>
          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
            <Typography variant="subtitle2">
              Coins:{upgradeBalance}
            </Typography>
          </Box>
          </button> */}
          <button onClick={handleWalletClick} className='withdrawButton'>
          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
            <Typography variant="subtitle2">
              Wallet:{mainBalance}
            </Typography>
          </Box>
          </button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
