// src/components/Footer.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Footer = () => {
  return (
    <Box p={2} textAlign="center" style={{ backgroundColor: 'transparent', color: '#FFF' }}>
      <Typography variant="h6">DOWNLOAD OUR APP</Typography>
      <Button variant="contained" color="success" style={{ marginTop: 10 }}>
        Download Now
      </Button>
    </Box>
  );
};

export default Footer;
