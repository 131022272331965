import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import '../styles/LudoClassicManual.css';
import { useNavigate } from 'react-router-dom';

const LudoClassicManual = () => {
  const audioRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openGames, setOpenGames] = useState([]);
  const [runningGames, setRunningGames] = useState([]);
  const [mobile, setMobile] = useState('');
  const [amount, setAmount] = useState('');

  const fetchOpenGames = async () => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/opengames');
      const data = response.data.recordset;
      if (Array.isArray(data)) {
        const userId = localStorage.getItem('user');

        if (!userId) {
          console.error('User ID is not defined');
          return;
        }

        const sortedData = data.sort((a, b) => {
          const isUserGameA = a.player1_id === userId;
          const isUserGameB = b.player1_id === userId;

          if (isUserGameA && !isUserGameB) return -1;
          if (!isUserGameA && isUserGameB) return 1;
          return 0;
        });
        setOpenGames(sortedData);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (error) {
      console.error('Error fetching open games:', error);
    }
  };

  // Function to fetch running games
  const fetchRunningGames = async () => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/runninggames');
      const data = response.data.recordset;

      if (Array.isArray(data)) {
        const userId = localStorage.getItem('user');

        if (!userId) {
          console.error('User ID is not defined');
          return;
        }
        const sortedData = data.sort((a, b) => {
            const isUserGameA = a.player1_id === userId;
            const isUserGameB = b.player1_id === userId;
  
            if (isUserGameA && !isUserGameB) return -1;
            if (!isUserGameA && isUserGameB) return 1;
            return 0;
          });
        setRunningGames(sortedData);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (error) {
      console.error('Error fetching running games:', error);
    }
  };
  useEffect(() => {
    // Play the audio when the component is mounted
    const playAudio = async () => {
      try {
        if (audioRef.current) {
          await audioRef.current.play();
        }
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    };

    playAudio();

    // Cleanup function to stop the audio when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset the audio to start
      }
    };
  }, []);

  useEffect(() => {
    const userMobileNumber = localStorage.getItem('user');
    if (userMobileNumber) {
        setMobile(userMobileNumber);
    }
    const fetchData = async () => {
      try {
        await Promise.all([fetchOpenGames(), fetchRunningGames()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleSetClick = async () => {
    const userMobileNumber = localStorage.getItem('user');

    if (!userMobileNumber) {
      toast.error('User not logged in.');
      return;
    }

    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error('Please enter a valid amount.');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post('https://api.successfamily.live/api/battlecreate', {
        mobileNumber: userMobileNumber,
        amount: parseFloat(amount)
      });

      const { message, status } = response.data;

      if (status === 1) {
        toast.success(message);
        setAmount(''); // Clear input field on success
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error('Error occurred while creating battle!');
      console.error('API Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const acceptRequest = async (game) => {
    const game_id = game.game_id;
    const player1_id = game.player1_id;
    try {
      const response = await axios.post('https://api.successfamily.live/api/acceptRequest', {
        game_id,
        player1_id
      });
      toast.success('Request accepted successfully');
      console.log('Request accepted successfully:', response.data);
      // Handle success
    } catch (error) {
        toast.warning('Error accepting request!');
      console.error('Error accepting request:', error.response ? error.response.data : error.message);
      // Handle error
    }
  };
  const rejectRequest = async (game) => {
    const game_id = game.game_id;
    const player1_id = game.player1_id;
    try {
      const response = await axios.post('https://api.successfamily.live/api/rejectrequest', {
        game_id,
        player1_id
      });
      toast.success('Request rejected successfully');
      console.log('Request rejected successfully:', response.data);
      // Handle success (e.g., update UI or show message)
    } catch (error) {
      if (error.response) {
        toast.error('Error rejecting request');
        console.error('Error rejecting request:', error.response.data.error);
        // Handle server error (e.g., show error message)
      } else {
        console.error('Error calling API:', error.message);
        // Handle network or other errors (e.g., show error message)
      }
    }
  };
  const deleteRequest = async (game) => {
    const game_id = game.game_id;
    try {
      const response = await axios.post('https://api.successfamily.live/api/deleterequest', {
        game_id
      });
  
      console.log('Request deleted successfully:', response.data);
      // Handle success (e.g., update UI or show message)
    } catch (error) {
      if (error.response) {
        console.error('Error deleted request:', error.response.data.error);
        // Handle server error (e.g., show error message)
      } else {
        console.error('Error calling API:', error.message);
        // Handle network or other errors (e.g., show error message)
      }
    }
  };

  const handleSendGameRequest = async (game) => {
    // Extract the amount from the state instead of the game object
    // const { game_id,amount } = game;
    const requestAmount = parseFloat(game.amount);
    console.log('Request Amount:', requestAmount); // Debug log
  
    if (!game || !game.game_id || isNaN(requestAmount) || requestAmount <= 0) {
      toast.error('Invalid game data or amount.');
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.post('https://api.successfamily.live/api/gamerequest', {
        game_id: game.game_id,
        requestingPlayer: mobile,
        amount: requestAmount
      });
      console.log('Request sent successfully:', response.data);
      if (response.data.status === 1) {
        toast.success('Request processed successfully');
        setAmount(''); // Clear input field on success
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error making request');
      console.error('Error making request:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleOpenGame = async (game) => {
    // Extract the amount from the state instead of the game object
     const game_id = game.game_id;
  
    if (!game || !game.game_id) {
      toast.error('Invalid game data');
      return;
    }
  
    setLoading(true);
    if (game_id) {
        navigate('/ludoplaying', { state: { game_id: game_id } });
      }
  
  };
  

  return (
    
    <div className="ludo-container">
         {/* <audio
        ref={audioRef}
        src="../assets/music/Sakura-Girl-Motivation-chosic.com_.mp3"
        preload="auto"
        loop // Set the audio to loop
      /> */}
      <div className="header-para">
        <p>
          🪄🪄 आप सभी की डिमांड पर 84 Ludo Hub लेकर आ गया है UPI से सबसे फास्ट विड्रोल 🪄🪄 | विड्रोल के लिए आपको बैंक डिटेल्स की जरूरत नहीं है पाये अब आप विड्रोल पाएंगे 🪄🪄 | और कहीं भी समस्या हो 2 मिनट में उसे समस्या का समाधान हो जाएगा व्हाट्सएप्प पर मैसेज कर दे 🪄🪄 |
        </p>
      </div>
      <div className="create-battle">
        <h3>Create a Battle!</h3>
        <div className="create-battle-input">
          <input 
            type="number" 
            placeholder="Enter amount" 
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button onClick={handleSetClick} disabled={loading}>
            {loading ? 'Processing...' : 'Set'}
          </button>
        </div>
      </div>
      <div className="open-battles">
        <h4>Open Battles</h4>
        <div className="running-battles">
          {openGames.length === 0 ? (
            <p>No open battles found.</p>
          ) : (
            openGames.map(game => (
                <div className="battle" key={game.game_id}>
                  <p>Challenge from {game.player1_name}</p>
                  <div className="battle-details">
                    <div className="entry-fee">
                      <p>Entry Fee</p>
                      <p>{game.amount}</p>
                    </div>
                    <div>
                    {mobile === game.player1_id && (game.RequestingPlayer === '' || game.RequestingPlayer === null) ? (
                        <button 
                        className="delete-battle" 
                        onClick={() => deleteRequest(game)}>
                        Delete
                      </button>
                    ) : mobile === game.RequestingPlayer ? (
                      <div>
                        <button className="delete-battle">Cancel</button>
                      </div>
                    ) : mobile === game.player1_id && (game.RequestingPlayer !== '' && game.RequestingPlayer != null) ? ( // Add your new condition here
                        <div>
                            <button 
                        className="start-battle" 
                        onClick={() => acceptRequest(game)}
                      >
                        Accept
                      </button>
                        <button 
                        className="delete-battle" 
                        onClick={() => rejectRequest(game)}
                      >
                        Reject
                      </button>
                        </div>
                    ) : (
                      <button 
                        className="start-battle" 
                        onClick={() => handleSendGameRequest(game)}
                      >
                        Play
                      </button>
                    )}
                    </div>
                    <div className="prize">
                      <p>Prize</p>
                      <p>{game.winningamount || 'N/A'}</p> {/* Adjust based on actual prize field */}
                    </div>
                  </div>
                </div>
              ))
              
              
          )}
        </div>
      </div>
      <div className="running-battles">
        <h4>Running Battles</h4>
        <div className="running-battles">
          {runningGames.length === 0 ? (
            <p>No Running battles found.</p>
          ) : (
            runningGames.map(game2 => (
                <div className="battle" key={game2.game_id}>
                  <p>Game Play Between {game2.player1_name} & {game2.player2_name}</p>
                  <div className="battle-details">
                    <div className="entry-fee">
                      <p>Entry Fee</p>
                      <p>{game2.amount}</p>
                    </div>
                    <div style={{display: 'grid'}}> 
                    <img src="../assets/images/versus.png" alt='vsimage' style={{width: '42px',height: '42px'}}></img>

                    {(mobile === game2.player1_id || mobile === game2.player2_id) && (
                <button 
                  className="start-battle" 
                  onClick={() => handleOpenGame(game2)}
                >
                  View
                </button>
              )}
                      </div>
                    <div className="prize">
                      <p>Prize</p>
                      <p>{game2.winningamount || 'N/A'}</p> {/* Adjust based on actual prize field */}
                    </div>
                  </div>
                </div>
              ))
              
              
          )}
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default LudoClassicManual;
