// src/components/Support.js
import React from 'react';
import { Box, Typography, Grid,Card, CardContent } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import '../styles/Support.css';

const Support = () => {
  return (
    <Box className="support-container">
      <Typography variant="h5" className="support-heading">Contact Us At Below Platforms.</Typography>
      <Grid container spacing={2} justifyContent="center" style={{marginTop: '5px'}}>
      <Grid item xs={6} sm={6} md={3} className="contact-item">
      <Card>
      <CardContent>
          <EmailIcon style={{ fontSize: 50 }} />
          <Typography style={{ fontSize: '0.75rem' }} variant="body1">help@84LudoHub.com</Typography>
        </CardContent>
        </Card>
        </Grid>
        <Grid item xs={6} sm={6} md={3} className="contact-item">
        <Card>
      <CardContent>
          <WhatsAppIcon style={{ fontSize: 50 }} />
          <Typography variant="body1">WhatsApp</Typography>
        </CardContent>
        </Card>
        </Grid>
        <Grid item xs={6} sm={6} md={3} className="contact-item">
        <Card>
      <CardContent>
          <PhoneIcon style={{ fontSize: 50 }} />
          <Typography style={{ fontSize: '0.75rem' }} variant="body1">9811791416 / <br></br>7428573986</Typography>
        </CardContent>
        </Card>
        </Grid>
      </Grid>
      <Typography variant="body1" className="address">
        Address: Building Patta No. , Ward No. , Landmark: , Locality: , 
        City: , District:  State:  PIN Code: 
      </Typography>
    </Box>
  );
};

export default Support;
