import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Refer.css';
import axios from 'axios';

const Refer = () => {
    const [mobile, setMobile] = useState('');
    const inputRef = useRef(null);
    const [referearning, setReferralEarning] = useState('');
  const [directReferral, setDirectReferral] = useState('');

  // Function to copy the input field value to clipboard
  const handleCopy = () => {
    if (inputRef.current) {
      const valueToCopy = `https://84ludohub.com/signup?id=${inputRef.current.value}`; // Static text prepended to the input value
      navigator.clipboard.writeText(valueToCopy)
        .then(() => {
          console.log('Copied to clipboard:', valueToCopy);
          toast.success('Copied to clipboard!', { position: 'top-center' });
        })
        .catch(err => {
          console.error('Failed to copy:', err);
          // Handle error
        });
    }
  };
    useEffect(() => {
        const userMobileNumber = localStorage.getItem('user');
        if (userMobileNumber) {
            setMobile(userMobileNumber);
            fetchReferralEarning(userMobileNumber);
            fetchDirectReferral(userMobileNumber);
        }
      }, []);

      const fetchReferralEarning = async (mobileNumber) => {
        try {
          const response = await axios.post('https://api.successfamily.live/api/referralearning', {
            mobileNumber
          });
    
          const { rearning } = response.data;
          setReferralEarning(rearning);
        } catch (error) {
          console.error('Failed to fetch wallet balance:', error.message);
          // Handle error, set balances to default or show an error message
          setReferralEarning('0');
        }
      };
    
      const fetchDirectReferral = async (mobileNumber) => {
        try {
          const response = await axios.post('https://api.successfamily.live/api/directreferral', {
            mobileNumber
          });
    
          const { userd } = response.data;
          setDirectReferral(userd);
        } catch (error) {
          console.error('Failed to fetch wallet balance:', error.message);
          // Handle error, set balances to default or show an error message
          setDirectReferral('0');
        }
      };
    return (
        <div className="refer-container">
            <div className="refer-code-section">
                <h3>Referral Code</h3>
                <div className="referral-code">
                    <input type="text" value={mobile} readOnly ref={inputRef} />
                    <button onClick={handleCopy}>Copy</button>
                    <ToastContainer />
                </div>
                <p>OR</p>
                <div className="social-icons">
                    <button className="whatsapp">WhatsApp</button>
                    <button className="facebook">Facebook</button>
                    <button className="telegram">Telegram</button>
                </div>
            </div>
            <div className="referral-earnings-section">
                <h4>Your Referral Earnings</h4>
                <div className="earnings-details">
                    <div className="referred-players">
                        <p>Referred Players</p>
                        <p>{directReferral}</p>
                    </div>
                    <div className="referral-earnings">
                        <p>Referral Earnings</p>
                        <p>₹{referearning}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Refer;
