import React, { useEffect, useState } from 'react';
import { Bolt, AccountBalance, Diversity3, Email, FindInPage } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles/Profile.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [username, setName] = useState('');
  const [useremail, setEmail] = useState('');
  const [mainBalance, setMainBalance] = useState('');
  const [gamesplay, setGamesPlay] = useState('');
  const [directReferral, setDirectReferral] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');

  useEffect(() => {
    const userMobileNumber = localStorage.getItem('user');
    if (userMobileNumber) {
      setMobileNumber(userMobileNumber);
      fetchDetails(userMobileNumber);
      fetchWalletBalance(userMobileNumber);
      fetchGamesPlayed(userMobileNumber);
      fetchDirectReferral(userMobileNumber);
    }
  }, []);
  
  const fetchDetails = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/userdetails', {
        mobileNumber
      });
      const name = response.data.name;
      const email = response.data.email;
      setName(name);
      setNewName(name);
      setEmail(email);
      setNewEmail(email);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setName('User Name Not Found');
        setEmail('');
      } else {
        setName('Failed to call API:', error.message);
      }
    }
  };

  const fetchWalletBalance = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/walletbalance', {
        mobileNumber
      });
      const { mainbal } = response.data;
      setMainBalance(mainbal);
    } catch (error) {
      console.error('Failed to fetch wallet balance:', error.message);
      setMainBalance('0');
    }
  };

  const fetchGamesPlayed = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/gamesplayed', {
        mobileNumber
      });
      const { played } = response.data;
      setGamesPlay(played);
    } catch (error) {
      console.error('Failed to fetch wallet balance:', error.message);
      setGamesPlay('0');
    }
  };

  const fetchDirectReferral = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/directreferral', {
        mobileNumber
      });
      const { userd } = response.data;
      setDirectReferral(userd);
    } catch (error) {
      console.error('Failed to fetch wallet balance:', error.message);
      setDirectReferral('0');
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('../login');
  };

  const handleClickWallet = () => {
    navigate('/wallet');
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEmailEditClick = () => {
    setIsEditingEmail(true);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleNameSaveClick = async () => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/updatename', {
        mobileNumber,
        newName
      });
      if (response.data.status === 1) {
        setName(newName);
      } else {
        alert('Failed to update name');
      }
    } catch (error) {
      console.error('Error updating name:', error.message);
      alert('Failed to update name');
    } finally {
      setIsEditing(false);
    }
  };

  const handleEmailSaveClick = async () => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/updateemail', {
        mobileNumber,
        newEmail
      });
      if (response.data.status === 1) {
        setEmail(newEmail);
      } else {
        alert('Failed to update email');
      }
    } catch (error) {
      console.error('Error updating email:', error.message);
      alert('Failed to update email');
    } finally {
      setIsEditingEmail(false);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-info">
          <img src="assets/icons/Avatar1.png" alt="Profile" className="profile-pic" />
          <div>
            {isEditing ? (
              <div className="edit-name">
                <div className="input-container">
                  <input type="text" value={newName} onChange={handleNameChange} />
                  <button className="wallet-button" onClick={handleNameSaveClick}>Save</button>
                </div>
              </div>
            ) : (
              <div className="display-name">
                <h3>{username}</h3>
                <button className="EditButton" onClick={handleEditClick}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </button>
              </div>
            )}
            {mobileNumber && <p>{mobileNumber}</p>}
            <div>
          <button className="wallet-button" onClick={handleClickWallet}>My Wallet</button>
        </div>
          </div>
          
        </div>
      </div>

      <div className="profile-section">
        <h3>Complete Profile</h3>
        <button className="profile-action-button">
          <FindInPage />
          KYC VERIFICATION
        </button>
        <button className="profile-action-button">
          <Email />
          {isEditingEmail ? (
            <div className="edit-email">
              <div className="input-container">
                <input type="email" value={newEmail} onChange={handleEmailChange} />
                <button className="wallet-button" onClick={handleEmailSaveClick}>Save</button>
              </div>
            </div>
          ) : (
            <div className="display-email">
              <p>{useremail}</p>
              <button className="EditButton" onClick={handleEmailEditClick}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </div>
          )}
        </button>
      </div>

      <div className="profile-section">
        <h3>Other Details</h3>
        <div className="details-cards">
          <div className="details-card">
            <AccountBalance />
            <p>Main Balance</p>
            <p>{mainBalance}</p>
          </div>
          <div className="details-card">
            <Bolt />
            <p>Battle Played</p>
            <p>{gamesplay}</p>
          </div>
          <div className="details-card">
            <Diversity3 />
            <p>Referral</p>
            <p>{directReferral}</p>
          </div>
        </div>
      </div>
      <button onClick={handleLogout} className="logout-button">
        Log Out
      </button>
    </div>
  );
}

export default Profile;
