import React, { forwardRef, useEffect, useState } from 'react';
import '../styles/Sidebar.css';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Home, AccountCircle, VerifiedUser, SportsEsports, AccountBalanceWallet, PersonAdd, History, Help, Logout, AccountBalance} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Sidebar = forwardRef(({ isOpen, toggleSidebar }, ref) => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();
useEffect(() => {
  // Fetch mobile number from local storage
  const userMobileNumber = localStorage.getItem('user');
  if (userMobileNumber) {
    setMobileNumber(userMobileNumber);
    fetchName(userMobileNumber);
  }
}, []);
const fetchName = async (mobileNumber) => {
  try {
    const response = await axios.post('https://api.successfamily.live/api/userdetails', {
     mobileNumber
    });
    
    // Assuming your API response is like { name: 'John Doe' }
    const name = response.data.name; // Adjust according to your API response structure

    // Update state with the fetched name
    setName(name);
  } catch (error) {
    if (error.response && error.response.status === 404) {
      setName('User Name Not Found');
    } else {
      setName('Failed to call api:', error.message);
    }
  }
};
const handleLogout = () => {
  // Clear the local storage
  localStorage.removeItem('token'); // Assuming 'token' is the key for user token
  localStorage.removeItem('user');  // Assuming 'user' is the key for user information

  // Redirect to the login page
  navigate('../login');
};

  return (
    <>
      <div ref={ref} className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <div className="profile">
            <img src="../assets/icons/Avatar1.png" alt="Profile" className="profile-pic" />
            {name && <h2>{name}</h2>}
            {mobileNumber && <p>{mobileNumber}</p>}
          </div>
        </div>
        <nav className="menu">
          <List component="ul" className="clrblack">
          <ListItem button component={Link} to="/home">
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/profile">
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItem>
            <ListItem button component={Link} to="/kyc">
              <ListItemIcon>
                <VerifiedUser />
              </ListItemIcon>
              <ListItemText primary="KYC" />
            </ListItem>
            <ListItem button component={Link} to="/ludoclassicmanual">
              <ListItemIcon>
                <SportsEsports />
              </ListItemIcon>
              <ListItemText primary="Play" />
            </ListItem>
            <ListItem button component={Link} to="/wallet">
              <ListItemIcon>
                <AccountBalanceWallet />
              </ListItemIcon>
              <ListItemText primary="My Wallet" />
            </ListItem>
            <ListItem button component={Link} to="/refer">
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              <ListItemText primary="Refer and Earn" />
            </ListItem>
            <ListItem button component={Link} to="/bankdetails">
              <ListItemIcon>
                <AccountBalance />
              </ListItemIcon>
              <ListItemText primary="Bank Details" />
            </ListItem>
            <ListItem button component={Link} to="/transactionhistory">
              <ListItemIcon>
                <History />
              </ListItemIcon>
              <ListItemText primary="History" />
            </ListItem>
            <ListItem button component={Link} to="/support">
              <ListItemIcon>
                <Help />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItem>
            <ListItem>
            <button className="logout-button2" onClick={handleLogout}><ListItemIcon>
                <Logout />
              </ListItemIcon> Logout </button>
              </ListItem>
          </List>
        </nav>
      </div>
    </>
  );
});

export default Sidebar;
