// src/components/Games.js
import React from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { useNavigate} from 'react-router-dom';

const games = [
  {
    title: "Ludo Classic",
    image: "../assets/images/ludo_classic.jpg",
    status: "LIVE",
    link: "/ludoclassicmanual",
  },
  {
    title: "Ludo Popular",
    image: "../assets/images/ludopopular.png",
    status: "COMING SOON",
    link: "",
  },
  {
    title: "Avitor",
    image: "../assets/images/Aviator.jpg",
    status: "COMING SOON",
    link: "",
  },
  {
    title: "Colour Prediction",
    image: "../assets/images/colourPrediction.jpg",
    status: "COMING SOON",
    link: "",
  },
  // Add more games as needed
];
const Games = () => {
  const navigate = useNavigate();
  const handleButtonClick = (url) => {
    navigate(url);
  };
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {games.map((game, index) => (
          <Grid item xs={6} sm={6} md={3} key={index}>
            <Card style={{boxShadow: '9px 9px 20px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'}}>
              <CardMedia
                component="img"
                alt={game.title}
                height="140"
                image={game.image}
                title={game.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" fontSize={'1rem'} component="div">
                  {game.title} ({game.status})
                </Typography>
                <Button variant="contained" onClick={() => handleButtonClick(game.link)} color="primary" fullWidth>
                  Play Now
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Games;
