import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../styles/LudoPlaying.css";
import Popup from "../components/Popup";

const LudoPlaying = () => {
  const location = useLocation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const game_id = location.state?.game_id;
  const [roomcode, setRoomCode] = useState("");
  const [mobile, setMobile] = useState("");

  const [gameid, setGameId] = useState(game_id);
  const [game, setGame] = useState(null);
  const [error, setError] = useState("");
  const [remainingTime, setRemainingTime] = useState(180); // 3 minutes in seconds
  const [timerEnded, setTimerEnded] = useState(false);
  const [loader, setLoader] = useState(false);
  const hasRefundBeenCalled = useRef(false);
  const [isEditingRoomCode, setIsEditingRoomCode] = useState(false); // New state for editing room code

  const copyCode = (roomCode) => {
    navigator.clipboard
      .writeText(roomCode)
      .then(() => {
        toast.success("Room Code Copied Successfully");
      })
      .catch((err) => {
        toast.error("Failed to copy");
        console.error("Failed to copy: ", err);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleWinningResultRequest = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }
    try {
      setLoader(true);
      let formdata = new FormData();
      formdata.append("game_id", gameid);
      formdata.append("resultid", mobile);
      formdata.append("playerimg", selectedFile);
      const { status, data } = await axios.post(
        "https://api.successfamily.live/api/winningresultrequest",
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { message } = data;
      if (status === 201) {
        toast.success(message, { position: "top-center" });
      } else {
        toast.warning(message, { position: "top-center" });
      }
    } catch (e) {
      toast.error(
        "Request failed: " + (e.response?.data?.message || e.message),
        { position: "top-center" }
      );
    } finally {
      setShowPopup(false);
      setLoader(false);
      setSelectedFile(null);
    }
  };

  const handleLosingResultRequest = async () => {
    try {
      const response = await axios.post(
        "https://api.successfamily.live/api/losingresultrequest",
        {
          game_id: gameid,
          resultid: mobile,
        }
      );

      const { message, status } = response.data;

      if (status === 1) {
        toast.success(message, { position: "top-center" });
      } else {
        toast.warning(message, { position: "top-center" });
      }
    } catch (error) {
      toast.error(
        "Request failed: " + (error.response?.data?.message || error.message),
        { position: "top-center" }
      );
    }
  };

  const cancelApi = async () => {
    if (hasRefundBeenCalled.current) return;
    try {
      const response = await axios.post(  
        "https://api.successfamily.live/api/cancelgame",
        { game_id: gameid }
      );
      hasRefundBeenCalled.current = true; // Set ref to true after successful call
      const { message, status } = response.data;
      setTimeout(() => {
        toast.success(message, { position: "top-center" });
        console.log(message);
      }, 3000); // Ensure the refund API is only called once
    } catch (error) {
      console.log(error);
      toast.error("Failed to process refund");
    }
  };

  const callRefundApi = async () => {
    if (hasRefundBeenCalled.current) return;
    try {
      const response = await axios.post(
        "https://api.successfamily.live/api/refundrequest",
        { game_id: gameid }
      );
      hasRefundBeenCalled.current = true; // Set ref to true after successful call
      console.log("Refund API response:", response);
      setTimeout(() => {
        toast.success("Refund processed successfully");
      }, 3000); // Ensure the refund API is only called once
    } catch (error) {
      console.error("Error processing refund:", error);
      toast.error("Failed to process refund");
    }
  };

  useEffect(() => {
    const userMobileNumber = localStorage.getItem("user");
    if (userMobileNumber) {
      setMobile(userMobileNumber);
    }
    const fetchRunningGames = async () => {
      try {
        const response = await axios.post(
          "https://api.successfamily.live/api/runninggamesid",
          { game_id: gameid }
        );
        const data = response.data.recordset;

        if (data.length > 0) {
          setGame(data[0]);
        } else {
          setError("No running games found");
        }
      } catch (error) {
        console.error("Error fetching running games:", error);
        setError("Error fetching running games");
      }
    };

    if (gameid) {
      const interval = setInterval(fetchRunningGames, 1000);
      return () => clearInterval(interval);
    }
  }, [gameid]);

  useEffect(() => {
    if (!game || !game.battle_accept_time) return;

    const battleAcceptTime = new Date(game.battle_accept_time);
    const battleAcceptTimeIST = new Date(
      battleAcceptTime.getTime() - 5.5 * 60 * 60 * 1000
    ); // Convert to IST
    const endTime = new Date(battleAcceptTimeIST.getTime() + 3 * 60 * 1000); // battle_accept_time + 3 minutes

    const updateRemainingTime = () => {
      const now = new Date();
      const remaining = Math.max(0, Math.floor((endTime - now) / 1000));
      if (remaining === 0) {
        setTimerEnded(true);
        if (!hasRefundBeenCalled.current && (!game || !game.RoomCode)) {
          callRefundApi();
        }
      }
      setRemainingTime(remaining);
    };

    const interval = setInterval(updateRemainingTime, 1000);

    // Update remaining time immediately on mount
    updateRemainingTime();

    return () => clearInterval(interval);
  }, [game?.battle_accept_time, game?.RoomCode]);

  const handleRoomCode = (e) => {
    setRoomCode(e.target.value);
  };
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleUpdateRoom = async () => {
    try {
        const response = await axios.post(
            "https://api.successfamily.live/api/updateroom",
            {
                roomcode: String(roomcode),  // Ensure roomcode is treated as a string
                game_id: parseInt(game_id, 10)
            }
        );
        const { message, status } = response.data;
        if (status === 1) {
            toast.success("Room Code Set Successfully", { position: "top-center" });
            setIsEditingRoomCode(false); // Exit edit mode after successful update
        } else {
            toast.warning(message, { position: "top-center" });
        }
    } catch (error) {
        toast.warning(
            "Failed Room Update: " +
            (error.response?.data?.message || error.message),
            { position: "top-center" }
        );
    }
  };

  const handleEditRoomCode = () => {
    setIsEditingRoomCode(true); // Enable edit mode
  };

  const handlePopupClose = () => {
    setSelectedFile(null);
    setShowPopup(false);
  };

  return (
    <div className="ludo">
      <div className="game-room">
        {game ? (
          <>
            <div className="players-info">
              <div className="player-info">
                <div>
                  <h3>{game.player1_name}</h3>
                  <img
                    className="userimage"
                    src="../assets/images/Avatar2.png"
                    alt="User Image"
                  />
                  <p>Entry Fee: {game.amount}</p>
                </div>
                <div style={{ margin: "auto" }}>
                  <img
                    src="../assets/images/versus.png"
                    alt="vsimage"
                    style={{ width: "42px", height: "42px" }}
                  />
                </div>
                <div>
                  <h3>{game.player2_name}</h3>
                  <img
                    className="userimage"
                    src="../assets/images/Avatar2.png"
                    alt="User Image"
                  />
                  <p>Winning Prize: {game.winningamount}</p>
                </div>
              </div>
            </div>
            <div className="roomcode">
  {!isEditingRoomCode ? (
    // Show room code and "Edit Room" button when not in edit mode
    <>
      <h4>Room Code: {game.RoomCode}</h4>
      {game.player1_id === mobile && !timerEnded && !game.RoomCode && (
      <>
      <button className="EditRoom" onClick={handleEditRoomCode}>
      
      Update Room   <FontAwesomeIcon icon={faPencilAlt} />
      </button>
      </>
      )}
      {game.RoomCode && (
                <button onClick={() => copyCode(game.RoomCode)}>
                  COPY CODE
                </button>
              )}
    </>
  ) : (
    // Show input field and "Update Room" button when in edit mode
    <>
     <div className="room-code">
     {game.player1_id === mobile && !timerEnded && !game.RoomCode && (
      <>
      <div className="edit-name">
      <div className="input-container">
        <input
          type="text"
          value={roomcode}
          onChange={handleRoomCode}
          placeholder="Enter Room Code"
        />
        </div>
        </div>
        <button className="setRoomBtn" onClick={handleUpdateRoom}>
          Update Room
        </button>
      </>
        )}
         </div>
    </>
  )}
</div>

<strong>
                  {game.RoomCode
                    ? `Room Code is: ${game.RoomCode}`
                    : !timerEnded &&
                      `Remaining Time For Room Code: ${formatTime(
                        remainingTime
                      )}`}
                </strong>
          </>
        ) : (
          <p>{error}</p>
        )}
      </div>
      <div className="app-links">
          <p>Play ludo game in Ludo King App</p>
          <img
            src="../assets/images/app-store-logo-android-4.png"
            alt="App Store"
          />
        </div>
        <div className="game-rules">
          <h4>Game Rules</h4>
          <ul>
            <li>
              यह ludoClassicManual है यहाँ आप SET ROOM CODE में दुसरे गेम के कोड
              देने या खेलने करते हैं तो देखो रद्द कर दिया जाएगा और Penalty लगा
              दी जाए
            </li>
            <li>Record Every Game While Playing.</li>
            <li>For Cancellation Of Game, Video Proof Is Necessary.</li>
            <li>
              महत्त्वपूर्ण: नोटिफिकेशन गलत गेम परिणाम जमा करने के बाद, आपका
              अमाउंट वापस नहीं किया जाएगा!
            </li>
            <li>
              महत्त्वपूर्ण: यदि आप के रद्द करने के प्रमाण आपके नहीं लगते हैं, तो
              आपके वोटिंग के बाद केस पुनरीक्षण किया जाएगा। रिसल्ट आने के बाद के
              25 बाद तक पैसा रिलीज़ नहीं किया जाएगा!
            </li>
          </ul>
        </div>
        <div className="game-status">
          <h4>Update Game Status</h4>
          <p>
            After completion of your game, select the status of the game and
            post your screenshot below.
          </p>
          </div>
      {showPopup && (
        <Popup
        onClose={handlePopupClose}
        onSubmit={handleWinningResultRequest}
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
        isLoader={true}
        loader={loader}
      />
      )}
      <div className="buttons-container">
  <button className="winning" onClick={() => setShowPopup(true)}>
    I Won
  </button>
  <button className="winning" onClick={handleLosingResultRequest}>
    I Lost
  </button>

  {/* Render "Cancel Battle" button only if room code is not set */}
  {!game?.RoomCode && (
    <button className="winning" onClick={cancelApi}>
      Cancel Battle
    </button>
  )}
</div>

      <ToastContainer />
    </div>
  );
};

export default LudoPlaying;
