import React, { useState, useEffect,useRef} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AddCash.css';
import axios from 'axios';

const AddCash = () => {
    const [mobile, setMobile] = useState('');
    const [amount, setAmount] = useState(100);
    const inputRef = useRef(null);
    const [transaction, setTransaction] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [Account, setAccountNumber] = useState('');
    const [IfscCode, setIfscCode] = useState('');
    const [UpiImage, setUpiImage] = useState('');
    const [UpiCode, setUpiCode] = useState('');

    const handleCopy = () => {
      if (inputRef.current) {
        const valueToCopy = `${inputRef.current.value}`; // Static text prepended to the input value
        navigator.clipboard.writeText(valueToCopy)
          .then(() => {
            console.log('Copied to clipboard:', valueToCopy);
            toast.success('Copied to clipboard!', { position: 'top-center' });
          })
          .catch(err => {
            console.error('Failed to copy:', err);
            // Handle error
          });
      }
    };

    useEffect(() => {
        const userMobileNumber = localStorage.getItem('user');
        if (userMobileNumber) {
            setMobile(userMobileNumber);
            fetchAccountDetails(userMobileNumber);
        }
      }, []);

    const fetchAccountDetails = async (mobileNumber) => {
        try {
          const response = await axios.post('https://api.successfamily.live/api/accountdetails', {
          });
    
          const { accountname,accountno,ifsccode,upiimage,upiid } = response.data;
          setAccountName(accountname);
          setAccountNumber(accountno);
          setIfscCode(ifsccode);
          setUpiImage(upiimage);
          setUpiCode(upiid);
        } catch (error) {
          console.error('Failed to fetch wallet balance:', error.message);
          // Handle error, set balances to default or show an error message
          setAccountName('');
          setAccountNumber('');
          setIfscCode('');
        }
      };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };
    const handletransactionChange = (e) => {
        setTransaction(e.target.value);
    };

    const handlePresetClick = (value) => {
        setAmount(value);
    };
    const handleSubmit = async () => {
      if (amount < 100) {
        // Show a warning toast if the amount is less than 300
        toast.warning('Add amount must be at least 100', { position: 'top-center' });
        return; // Exit the function early
      }
        try {
          const response = await axios.post('https://api.successfamily.live/api/addcash', {
            mobile,
            amount,
            transaction,
          });
          const { message , status } = response.data;
          console.log(response.data);
          if (status === 1) {
          toast.success('Add Cash Submit Successfully', { position: 'top-center' });
          } else {
            toast.warning(message, { position: 'top-center' });
          }
          
        } catch (error) {
          toast.warning('Add Cash failed:' + (error.response?.data?.message || error.message), { position: 'top-center' });
        }
      };

    return ( 
        <>
        <div className="Addcashheading">
        <h1>Add Money</h1>
        </div>
        <div className="add-cash-container">
            <h3>Choose Amount To Add</h3>


            <div className="AccountDetails">
                <h2 style={{Margin:'10px'}}>Account details</h2>
            <label htmlFor="amount">Account Name: {AccountName} </label>
            <label htmlFor="amount">Account No: {Account} </label>
            <label htmlFor="amount">Ifsc Code: {IfscCode} </label>
            <img 
            src={`https://admin.84ludohub.com/admin/Uploadupi/${UpiImage}`} 
            width="200px" 
            style={{ margin: 'auto',marginTop: '10px' }} 
            alt="UPI Image" 
            />
              <div className="refer-code">
              <div className="refer-code-section">
                <div className="referral-code">
                    <input type="text" value={UpiCode} readOnly ref={inputRef} />
                    <button onClick={handleCopy}>Copy</button>
                </div>
                </div>
            </div>

            </div>
            <div className="input-section">
                <label htmlFor="amount">Enter Amount</label>
                <div className="amount-input">
                    <span>💰</span>
                    <input 
                        type="number" 
                        id="amount" 
                        value={amount} 
                        onChange={handleAmountChange} 
                    />
                </div>
            </div>
            <div className="input-section">
                <label htmlFor="amount">Enter Transaction Id</label>
                <div className="amount-input">
                    <input 
                        id="transaction" 
                        onChange={handletransactionChange} 
                    />
                </div>
            </div>
            <div className="preset-buttons">
                <button onClick={() => handlePresetClick(100)}>💰100</button>
                <button onClick={() => handlePresetClick(250)}>💰250</button>
                <button onClick={() => handlePresetClick(500)}>💰500</button>
                <button onClick={() => handlePresetClick(2000)}>💰2000</button>
            </div>
            <button className="next-button" onClick={handleSubmit}>Submit</button>
            <ToastContainer />
        </div>
        </>
    );
};

export default AddCash;
