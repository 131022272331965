import React, { useState, useEffect } from 'react';
import { AccountBalance, Savings } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import '../styles/Wallet.css';

function Wallet() {
  const navigate = useNavigate();
  const [mainBalance, setMainBalance] = useState('');
  const [upgradeBalance, setUpgradeBalance] = useState('');

  useEffect(() => {
    const userMobileNumber = localStorage.getItem('user');
    if (userMobileNumber) {
      fetchWalletBalance(userMobileNumber);
    }
  }, []);

  const handleAddCashClick = () => {
    navigate('/addcash');
  };
  const handleWithdrawalClick = () => {
    navigate('/withdrawal');
  };
  const handleOrderHistoryClick = () => {
    navigate('/transactionhistory');
  };
  const fetchWalletBalance = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/walletbalance', {
        mobileNumber
      });

      const { mainbal, upgradebal } = response.data;
      setMainBalance(mainbal);
      setUpgradeBalance(upgradebal);
    } catch (error) {
      console.error('Failed to fetch wallet balance:', error.message);
      // Handle error, set balances to default or show an error message
      setMainBalance('0');
      setUpgradeBalance('0');
    }
  };
  return (
    <>
    <div className="wallet-container">
      <header className="wallet-header">
        <h1>My Wallet</h1>
        <button className="order-history-button" onClick={handleOrderHistoryClick}>Order History</button>
      </header>
      <div className="wallet-cards">
        {/* <div className="wallet-card">
          <div className="wallet-card-header">
            <AccountBalance />
            <h3 style={{paddingLeft: '10px'}}>Deposit Cash <span className="coins">{upgradeBalance}</span></h3>
          </div>
          <p>Can be used to play Tournaments & Battles. Cannot be withdrawn to Paytm or Bank.</p>
          <button onClick={handleAddCashClick} className="wallet-card-button">Add Cash</button>
        </div> */}
        <div className="wallet-card">
          <div className="wallet-card-header">
           <Savings />
            <h3 style={{paddingLeft: '10px'}}>Wallet Balance <span className="coins">{mainBalance}</span></h3>
          </div>
          <p>Can be withdrawn to Paytm or Bank. Can be used to play Tournaments & Battles.</p>
          <button onClick={handleAddCashClick} className="wallet-card-button"> Add Cash</button>
          <button onClick={handleWithdrawalClick} className="wallet-card-button">Withdraw</button>
        </div>
      </div>
    </div>
    </>
  );
}

export default Wallet;
