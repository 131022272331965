import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Login.css';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  // const [error, setError] = useState('');

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  useEffect(() => {
    // Check if the user is already logged in
    const loggedInUser = localStorage.getItem('user');
    console.log(id);
    if (loggedInUser) {
      navigate('/main', { state: { mobileNumber: loggedInUser } });
    }
  }, [navigate, id]);

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCreate = async () => {
    try {
      const response = await axios.post('https://api.successfamily.live/api/createaccount', {
        mobileNumber,
        sponserid: id, // Ensure this matches the expected parameter name in your backend
        password,
      });
      const { message , status } = response.data;
      if (status === 1) {
      toast.success('Register successful', { position: 'top-center' });
      }else {
        toast.warning(message, { position: 'top-center' });
      }
      
    } catch (error) {
      console.error('Register failed:', error);
      toast.warning('Register failed:' + (error.response?.data?.message || error.message), { position: 'top-center' });
    }
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className="auth-container">
      <header className="header">
        <img src="../assets/images/logo.png" alt="Logo" className="logo2" />
        <button className="auth-button" onClick={handleLoginClick}>Login</button>
      </header>
      <div className="auth-form">
        <h2 className="auth-header">Sign up</h2>
        <div className="input-container">
          <span>+91</span>
          <input
            type="text"
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            placeholder="Mobile number"
          />
        </div>
        <div className="input-container">
          <input
            type="password" // Use type="password" for the password input field
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
          />
        </div>
        <button className="otp-button" onClick={handleCreate}>Sign Up</button>
        <ToastContainer />
        <p className="footer-text">
          By continuing I agree that MB SMART WEB PRIVATE LIMITED may store and process my data in accordance with the 
          About Contact Privacy Policy
          and that I am 18 years or older. I am not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
        </p>
      </div>
    </div>
  );
};

export default Signup;
