import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/BankDetails.css';

const BankDetails = () => {
  const [formData, setFormData] = useState({
    accountHolderName: '',
    upiId: '',
    confirmUpiId: '',
    accountNumber: '',
    confirmAccountNumber: '',
    ifscCode: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (Object.values(formData).some(field => field === '')) {
      toast.error('All fields are required');
      return;
    }

    if (formData.upiId !== formData.confirmUpiId) {
      toast.error('UPI ID and Confirm UPI ID do not match');
      return;
    }

    if (formData.accountNumber !== formData.confirmAccountNumber) {
      toast.error('Account Number and Confirm Account Number do not match');
      return;
    }

    // Fetch mobile number from localStorage
    const mobileNumber = localStorage.getItem('user');
    if (!mobileNumber) {
      toast.error('Mobile number not found');
      return;
    }

    // Prepare data for API call
    const payload = {
      mobileNumber, // Replace with actual mobile number
      accountName: formData.accountHolderName,
      accountNo: formData.accountNumber,
      upiId: formData.upiId,
      ifscCode: formData.ifscCode
    };

    // Call API
    try {
      const response = await fetch('https://api.successfamily.live/api/updatebankdetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (result.status === 1) {
        toast.success('Bank Details Updated Successfully');
      } else {
        toast.error(result.message || 'Failed to update bank details');
      }
    } catch (error) {
      toast.error('An error occurred while updating bank details');
    }
  };

  return (
    <div className="bank-details-container">
      <ToastContainer />
      <h2>Enter your details carefully</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="accountHolderName">Account holder name</label>
          <input type="text" id="accountHolderName" value={formData.accountHolderName} onChange={handleChange} placeholder="Account holder name" />
        </div>
        <div className="form-group">
          <label htmlFor="upiId">Upi ID</label>
          <input type="text" id="upiId" value={formData.upiId} onChange={handleChange} placeholder="upi number" />
        </div>
        <div className="form-group">
          <label htmlFor="confirmUpiId">Confirm Upi ID</label>
          <input type="text" id="confirmUpiId" value={formData.confirmUpiId} onChange={handleChange} placeholder="Confirm upi number" />
        </div>
        <div className="form-group">
          <label htmlFor="accountNumber">Account number</label>
          <input type="text" id="accountNumber" value={formData.accountNumber} onChange={handleChange} placeholder="Account number" />
        </div>
        <div className="form-group">
          <label htmlFor="confirmAccountNumber">Confirm account number</label>
          <input type="text" id="confirmAccountNumber" value={formData.confirmAccountNumber} onChange={handleChange} placeholder="Confirm account number" />
        </div>
        <div className="form-group">
          <label htmlFor="ifscCode">IFSC code</label>
          <input type="text" id="ifscCode" value={formData.ifscCode} onChange={handleChange} placeholder="IFSC code" />
        </div>
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default BankDetails;
