import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import BottomMenuBar from '../components/BottomMenuBar';
import Games from '../components/Games';


const Index = () => {
    const navigate = useNavigate();
    const handleLoginClick = () => {
        navigate('/login');
      };
  return (
    <>
       <header className="header">
        <img src="../assets/images/logo.png" alt="Logo" className="logo2" />
        <button className="auth-button" onClick={handleLoginClick}>Login</button>
      </header>
      <Sidebar />
      <div className="container">
        <Games />
      </div>
      <BottomMenuBar />
    </>
  );
};

export default Index;
